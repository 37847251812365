import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';

export default makeStyles<Theme>((theme) =>
  createStyles({
    button: {
      backgroundColor: ColorsPalette.primary,
      border: '2px solid ' + ColorsPalette.primary,
      borderRadius: '100px',
      color: ColorsPalette.neutral1,
      cursor: 'pointer',
      fontFamily: FontCollection.raleway,
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 'bold',
      height: '20px',
      lineHeight: '124%',
      padding: '16px 44px',
      width: 'fit-content',
      [theme.breakpoints.down('md')]: {
        backgroundColor: ColorsPalette.neutral2,
        color: ColorsPalette.primary,
        marginTop: '34px',
      },
    },
    buttonGroup: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      top: '50%',
    },
    buttonWrap: {
      alignItems: 'flex-end',
      display: 'flex',
      height: '122px',
    },
    carousel: {
      display: 'flex',
      overflow: 'hidden',
      position: 'relative',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        overflow: 'auto',
        padding: '0',
        ['& > ul']: {
          ['& > li:nth-child(1)']: {
            paddingLeft: '1rem',
          },
          ['& > li']: {
            paddingLeft: '0.5rem',
          },
        },
      },
    },
    carouselArrows: {
      color: ColorsPalette.neutral2,
      height: '14px',
      width: '14px',
    },
    carouselItems: {
      [theme.breakpoints.down('md')]: {
        padding: '0',
      },
      padding: '0 10px',
    },
    hideElement: {
      display: 'none',
    },
    imageBlock: {
      margin: '0 10px',
      width: '450px',
      [theme.breakpoints.down('md')]: {
        marginTop: '10px',
        width: '500px',
      },
    },
    left: {
      background: ColorsPalette.primary,
      border: '0',
      borderRadius: '35px',
      cursor: 'pointer',
      left: '1px',
      lineHeight: '10px',
      marginTop: '-50px',
      minHeight: '43px',
      minWidth: '43px',
      mozTransform: 'rotate(180deg)',
      msTransform: 'rotate(180deg)',
      oTransform: 'rotate(180deg)',
      opacity: '1',
      outline: '0',
      position: 'absolute',
      top: '45%',
      transform: 'rotate(180deg)',
      transition: 'all .5s',
      webkitTransform: 'rotate(180deg)',
      zIndex: 1000,
    },
    productContainer: {
      '&:first-child': {
        [theme.breakpoints.down('md')]: {
          paddingLeft: '10px',
        },
      },
      flexDirection: 'column',
    },
    productImageContainer: {
      marginBottom: '0.25rem',
      width: '15.25rem',
    },
    productImageContainerLink: {
      textDecoration: 'none !important',
    },
    productsWrapper: {
      display: 'flex',
      height: '470px',
      justifyContent: 'space-between',
      marginBottom: '0.5rem',
      overflow: 'hidden',
      position: 'relative',
      width: '100%',
    },
    right: {
      background: ColorsPalette.primary,
      border: '0',
      borderRadius: '35px',
      cursor: 'pointer',
      lineHeight: '10px',
      marginTop: '-50px',
      minHeight: '43px',
      minWidth: '43px',
      opacity: '1',
      outline: '0',
      position: 'absolute',
      right: '1px',
      top: '45%',
      transition: 'all .5s',
      zIndex: 1000,
    },
  }),
);
